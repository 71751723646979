.previewCard {
    height: 100vh;
  }
  .borderStyle{
    border-left: 5px solid #4285f4 !important;
    // border-right:2px solid #4285f4 !important;
    // border-top:  1px solid #4285f4;
    // border-bottom:  1px solid #4285f4;
  }
  .addQuestionCard{
    background-color: (0, 0, 0, 0.06);
  }
  .addQuestionCard {
    box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 6px !important;
    background-color: #fff;
    // display: flex;
    padding-bottom: 10px  !important;
    // position: relative;
  
  }
  .linkBox {
    cursor: pointer; /* Changes cursor to pointer on hover */
}
// .deleteIcon{
//   color:#af0606 !important;
// }
.conditionalBorder{
  border:1px solid #4285f4 !important;
}
.itemGroupConditionalBorder{
  border:1px solid #13171f !important;
}
.rightEnd {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.itemGroup{
  background-color: #fdfcfc;
  border: 1px solid #e7e7e7;
}
.itemGroupHeader{
  background-color: #fff !important;
}
// .itemGroupButton{
//   display: flex;
//   align-items: flex-end;
// }
.itemCard{
  border: 1px solid #e7e7e7;
}
.selectedCard {
  border: 1px solid #4285f4 !important;
}

.no-hover-effect {
  &:hover {
    background-color: transparent !important; /* Remove default background color on hover */
    transform: none !important; /* Remove transform on hover */
    cursor: default !important; /* Change cursor if needed */
  }
}
.tradeDescription{
  justify-content: center;
  place-items: center ;
  display: flex;
  font-size:20px;
  font-weight:500;
  // margin-bottom: 13px !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-radius: 4px;
}
.tradeHeader{
   background-color: #e7e7e7;
}
.boxShadow{
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

}
