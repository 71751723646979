.btn-card-style {
    padding: 2px ;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px ;    
   border-color: #ea5455;
}

.height-custom{
    height: 100px;
}

