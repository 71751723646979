.custom-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
  .icon-avatar-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon-avatar-card h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .icon-avatar-card .MuiAvatar-root {
    width: 48px;
    height: 48px;
  }
  
  .icon-avatar-card .MuiAvatar-variantSquare {
    border-radius: 0;
  }
  
  .icon-avatar-card .MuiAvatar-variantRounded {
    border-radius: 50%;
  }
  
  .custom-card h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .custom-card .MuiAvatar-root {
    width: 48px;
    height: 48px;
    border: 2px solid #fff;
  }
  
  .custom-card .MuiAvatar-root:hover {
    transform: scale(1.1);
  }
  .letter-avatar-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .letter-avatar-card h4 {
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .letter-avatar-card .MuiAvatar-root {
    width: 48px;
    height: 48px;
  }
  
  