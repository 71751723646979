.tenantCard{
    place-items: center;
    justify-content: center;
}
.tenantImageUpload {
    width: 100% !important;
    height: 250px !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    background-color: rgb(255, 255, 255) !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 6px !important;
    box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
    background-image: none !important;
    overflow: hidden !important;
  }
  .contractCard {
    box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 6px !important;
    background-color: #fff;
    // display: flex;
    margin-bottom: 5px;
  
    padding: 0.5rem;
    position: relative;
    margin-top: 12px !important;
  
  }
  .POCard {
    box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 6px !important;
    background-color: #fff;
    // box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
    background-image: none !important;
    overflow: hidden !important;
  }
  