@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


html,
body,
#root,
.app,
.content {
  // height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  // overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto;
  text-transform: none !important;
  /* Hide vertical scrollbar */
}


.app {
  display: flex;
  flex-direction: row;
  position: relative;
}

::-webkit-scrollbar {
  // width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c6c6;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #bcbbbb;
}

.z-index-custom {
  z-index: 1030;
  // flex-direction: column;
}

#main {
  background-color: #7b7b7b00;
  transition: margin-left .3s;
  // padding: 16px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.card-color {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 235, 241);
  border-radius: 5px;
}

.header {
  padding-top: 5px;
  padding-bottom: 10px;
}

.logo-img {
  max-height: 60px;
}


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  overflow-x: "hidden";

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.3;
    // padding: 10px;
  }
}

#GChart {
  width: 68vw;
  // float:right;
  // width: 28%;
  margin-right: 3px;
  float: left;
  border: 1px solid #dbd6d6 !important;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  box-sizing: inherit;
  overflow-y: hidden;
  padding-top: 0;
  height: 800px;
  max-height: 800px;
  padding-left: 0;
  padding-right: 0;

  // height: 75vh; /* set height to window screen height */
  @media screen and (max-width: 767px) {
    .card-container {
      grid-template-columns: 1fr;
    }

    #GChart {
      margin-right: 0;
      // margin-bottom: 3px;
    }
  }
}

// .card {
//   // float:right;
//   width: 300px;
//   border: 1px solid #ccc;
//   border-radius: .2rem;

//   height: 500px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;

//   height: 800px;
//   max-height: 800px;
// }

.card-container {
  display: flex;
  // flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
}


#GChart {
  width: 70vw;
  flex: 0 0 70%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1px;
  box-sizing: inherit;
  //  overflow-y: scroll;
  height: 430px;
  max-height: 800px;
  padding-top: 0;
  margin-left: 0;
  overflow-x: hidden;
}

// .card {
//   flex: 0 0 30%;
//   border: 1px solid #ccc;
//   border-radius: .2rem;
//   // padding: 10px;
//   height: 500px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   height: 800px;
//   max-height: 800px;
// }

.card-container {
  // padding:3px;
  width: 100%;
  height: 100%;
}

// .card {
//   width: 28%;
//   // float:right;
//   // position: relative;
//   display: flex;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 0 solid rgba(0, 0, 0, .125);
//   border-radius: .2rem;
//   border: 1px solid #ccc;
//   height: 100%;
//   // height: 75vh; /* set height to window screen height */
//   // overflow-y: scroll;
//   // height: auto;
//   height: 800px;
//   max-height: 800px;
// }

.far {
  font-family: Font Awesome\ 5 Free;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
}

.card-title {
  float: left;
  font-size: 1.0rem;
  font-weight: 400;
  margin: 0;
  font-family: inherit;
  color: inherit;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: '20px',

}

::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: '4px',

}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(3, 117, 232);
  border-radius: 8px;

}

*,
:after,
:before {
  box-sizing: border-box;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ul {
  list-style-type: disc;
  // overflow-y: scroll;
  padding: 0 3px;
  margin: 0;
  height: auto;
}

.media {
  display: flex;
  align-items: flex-start;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F1F1F1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// img,
// svg {
//   vertical-align: middle;
// }

// img,
// svg {
//   vertical-align: middle;
// }

// img {
//   border-style: none;
// }

.media-body {
  flex: 1;
}

.card-container {
  position: relative;
  display: "grid",

}

.border-transparent {
  border-color: transparent !important;
}

.ViewContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-top: 3px;
}

.MuiButton-contained {
  text-transform: capitalize !important;
  font-size: 24px;
}

.MuiButton-outlined {
  text-transform: capitalize !important;

}

.MuiButton-text {
  text-transform: capitalize !important;

}


.ButtonContainer {
  display: flex;
  // justify-content: center;
  margin-bottom: 16px;
}

.button:active {
  active {
    box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15), inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  }
}

@media all and (max-width:30em) {
  .button {
    display: block;
    margin: 0.4em auto;
    text-transform: lowercase;
  }
}

.Switch {
  display: flex;
  // align-items: center;
}

.Switch_Toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-bottom: 3px;
}

.Switch_Toggle input {
  display: none;
}

.Slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: all 0.3s;
}

.Slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

.Switch_Toggle input:checked+.Slider {
  background-color: #5a5f5b;
}

.Switch_Toggle input:checked+.Slider:before {
  transform: translateX(20px);
}

// @media (max-width: 600px) {
//   .ButtonContainer {
//     display: block;
//   }
//   .Button {
//     margin-bottom: 8px;
//     width: 100%;
//   }
// }
.tabindex {
  border: 1px solid;
}

.makeStyles-cardTitle-101 {
  font-size: bold;
}

.cardHeader {
  .cardTitle {
    font-size: 18px;
    font-weight: bold;
  }
}


.cardTitle {
  float: left;
  font-size: 1.1rem !important;
  margin: 0 !important;
  font-family: inherit !important;
  // color: 'inherit',
  display: flex !important;
  align-items: center !important;
  // fontSize: '18px',
  font-weight: bold;
  color: #1565c0;

}

.ButtonContainer {
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1px;
  margin-top: 6px;
  // background-color: primary;
}

// .gantt-button{
//   background-color: #0a7fc8;
//   color: #0a7fc8;
//   font-size: 16px;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// }
#ganttChart {
  border: 1px solid #dbd6d6;
  font-size: medium;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


@media screen and (max-width: 768px) {
  .card-container {
    grid-template-rows: auto auto 1fr;
  }

  #GChart {
    margin-right: 0;
  }

  .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .card-container {
    display: block;
  }
}

@media (max-width: 768px) {
  .mt-7 {
    display: block;
  }
}

.Toolbar {
  color: black;
}


.cardFinance {
  float: left;
  width: 100%;
  border: 1px solid #dedede;
  flex: 0 0 65%; // new style
  height: 660px;
}

.main-div {
  display: inline-flex;
  margin-right: 6px;
}

.cardDetail {
  border-bottom: 1px solid #dedede;
  color: #242629;
  font-weight: 200;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;


}

cost-details {
  display: flex;
}



@media (min-width: 600px) {
  .Toolbar {
    min-height: 14px;
  }
}

#GChart {
  font-size: 14px;
  line-height: 1.3;
  padding: 3px;
}

.card-container {
  font-size: 14px;
  line-height: 1.3;
  // padding: 10px;
}

// Media query for iPhone
@media only screen and (max-width: 375px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;
    // padding: 10px;
  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;
    // padding: 10px;
  }
}


@media only screen and (max-width: 480px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }
}

// Media query for Samsung
@media only screen and (max-width: 640px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }
}

// Media query for Nexus
@media only screen and (max-width: 768px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }
}

// Media query for Nokia
@media only screen and (max-width: 768px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;
    padding: 10px;
  }
}

// Media query for Lenovo
@media only screen and (max-width: 768px) {
  #GChart {
    font-size: 14px;
    line-height: 1.3;

  }

  .card-container {
    font-size: 14px;
    line-height: 1.3;

  }
}

.GanttOriginal {
  height: calc(var(--row-height) * var(--bar-fill) / 100); // set the height based on the barFill value
  background-color: var(--bar-progress-color); // set the fill color
  border-radius: var(--bar-corner-radius); // set the corner rounding
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
  .card-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 820px) and (max-height: 1180px) {
  #GChart {
    width: 100%;
    flex: 1 0 auto;
    height: 100%;
    max-height: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-right: 20px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .ViewContainer {
    width: 100%;
    flex: 1 0 auto;

    border: none;
    border-radius: 0;
    padding: 0;
  }

  cost-details .ButtonContainer {
    width: 100%;
    flex: 1 0 auto;

    border: none;
    border-radius: 0;
    padding: 0;
  }
}

.cardFinance {
  border-radius: 5px;
  margin-right: 4px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
  font-weight: bold;
  background-color: #f1f1f1;
  height: auto;
}

#cost-details {
  padding-bottom: .25rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-size: medium;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  // background-color: #6c757d!important;
}

#costInfo {
  background-color: #6C757D;
  font-size: medium;
  font-weight: bold;
  border-radius: 3px;
  color: white;
  padding: 3.73px 6px;
  line-height: 1.6;
  letter-spacing: 0.0075em;

}

#costDollar {
  background-color: #28A745;
  font-size: medium;
  font-weight: bold;
  border-radius: 3px;
  color: white;
  padding: 3.73px 6px;
  line-height: 1.6;
  letter-spacing: 0.0075em;

}

.date-info {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.faCheckCircle {
  padding-right: 3px;
  color: #28A745;
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.flex-div1 {

  display: 'block';


  border-top: 3px solid #007Bff !important;
  margin-bottom: 2px;
  border: 1px solid #e0e0e0;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 5px;
  height: auto;
}

.cardTitle1 {
  color: inherit;
  float: left;
  margin: 0;
  display: flex;
  font-size: 1rem;
  align-items: center;
  font-family: inherit;
  font-weight: bold;
}

.FaExclamationTriangle {
  padding-right: 3px;
  color: #ffc107 !important;
  font-size: 24px;
  font-weight: bolder;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

// #paymentinfo {
//   background-color: #007BFF;
//   font-size: medium;
//   font-weight: bold;
//   border-radius: 3px;
//   color: white;
//   padding: 3.73px 6px;
//   line-height: 1.6;
//   letter-spacing: 0.0075em;
//   align-items: center;
//   text-align: center;
// }

// #make-payment {
//   margin-top: 6px;
//   margin-bottom: 4px;
//   padding-left: 0;
//   padding-right: 0;

// }

.modal {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .5);
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  // background-color: #fff;
  background-clip: padding-box;
  height: auto;


  outline: 0;
  border: 1px solid #ccc !important;
  box-sizing: border-box;

}

.moneyIcon {
  color: #fff;
  padding-bottom: 0px;
  margin-bottom: -6px;
  font-size: medium;
  font-weight: 600 !important;
}

@media only screen and (max-width: 600px) {
  .moneyIcon {
    font-size: small;
    margin-bottom: 8px;
    display: flex;
  }
}

.modalTitle {
  margin-bottom: 0;
  background-color: #FFFFFF;
  // borderBottom: '1px solid #ccc',
  font-weight: bold;
  font-size: 23px !important;
  font-family: Segoe UI, sans-serif;
  padding: 0;
  font-family: inherit;
  color: inherit;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  z-index: 1; // Make sure the element stays on top of other elements
  // backgroundColor: '#fff',
  margin-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  // border:'1px solid #e0e0e0',
  height: 50px;
  padding-left: 4px;


}

.button:active {
  background-color: none !important;
}

.button :hover {
  background-color: none !important;
}

.modalHead {
  padding: 0 0 !important;
  font-family: inherit;
  color: inherit;
  font-size: 23px !important;
  font-weight: 700 !important;
  letter-spacing: 0.0001em !important;
  padding-left: 5px !important;
}

.modalDetail {

  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

  // height:430px;     
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px;
  // box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,.5);  
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding-top: 1rem !important;
  display: block !important;
  margin-bottom: 20px;
}

.modalContent {
  margin-left: 0 !important;
  margin-bottom: 20px;

}

.form-group {
  margin-bottom: 1rem;
  box-sizing: border-box;

  display: inline-block;
  // padding-top: 1rem!important;
}

#header {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
  margin: 0 0 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modalContent #form-control {
  // display: block;
  // width: 100%;
  height: calc(2.25rem + 2px);
  margin-right: 10px !important;
  margin-top: 8px;
  // display:inline;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 12px;
  box-sizing: border-box;
  // width: 100%;
  width: 900px;
  max-width: 900px;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }
}

.modalContent {
  display: block;
}

#form-control1 {
  display: block;
  // width: 100%;
  height: calc(2.25rem + 2px);
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 12px;
  width: 630px;
  max-width: 640px;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }

}

.form-input {
  display: flex;
}

#cardicon {
  border: 1px solid #ccc;
  width: 270px;
  // margin-top: 8px;
  background-color: #E9ECEF;
  align-items: center;
  text-align: center;
  padding-top: 2px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

#card-month {
  display: block;
  // width: 100%;
  height: calc(2.25rem + 2px);
  padding-left: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 12px;
  width: 295px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }
}

#card-year {
  display: block;
  // width: 100%;
  height: calc(2.25rem + 2px);

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  // border-radius: .25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 12px;
  width: 295px;
  margin-right: 20px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }
}

.modalContent1 {
  display: flex;


}

#CVV {
  display: block;
  // width: 100%;
  height: calc(2.25rem + 2px);

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  // border-radius: .25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 6px 12px;
  width: 285px;
  max-width: 285px;
  padding-right: 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }
}

.modalContent .card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, .03);
  border-top: 0 solid rgba(0, 0, 0, .125);
  margin-top: 28px;
  width: 910px;
  max-width: 910px;
  margin-bottom: 10px !important;

}

.modalContent button {
  font-size: 16px;
  // background-color: #007Bff;
  cursor: pointer;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  display: block;
  width: 100%;
  color: #fff;
  // background-color: #007bff;
  border-color: #007bff;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-weight: 400;
  user-select: none;
  text-align: center;
  // vertical-align: middle;
}

.modalContent button:hover {
  background-color: #055fbe;
}

.modal label {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.FaHandHoldingUsd {
  margin-right: 6px;
  padding-right: 6px !important;
}

.form-field {
  padding: 5px;
  transition: border-color 0.3s ease-in-out;
  // width:100%;

  &:focus {
    outline: none;
    border-color: #699ed3;
  }
}

// Define the default styles for the root element
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

// Apply different styles for screens with a width less than or equal to 600px
@media (max-width: 800px) and (max-width: 1180px) {
  .root {
    height: auto;
  }
}

.cardHeader {
  margin-bottom: 0;
  background-color: #FFFFFF;
  // borderBottom: '1px solid #ccc',
  font-weight: bold;
  font-size: 500;
  padding: 0;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 1; // Make sure the element stays on top of other elements
  // backgroundColor: '#fff',
  margin-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  // border:'1px solid #e0e0e0',
  height: 40px !important;
  padding-left: 4px;


}

.flexdiv {
  // float:'left,
  display: block;
  // flexWrap: "wrap",
  height: 260px;
  border-top: 3px solid #28A745 !important;
  margin-bottom: 4px;
  border: 1px solid #e0e0e0;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 5px;


}

@media (max-width: 767px) {

  /* for mobile devices */
  .flexdiv {
    height: auto;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /* for tablet devices */
  .flexdiv {
    height: auto;
    flex-direction: column;
    // max-width: 50%;
  }
}

@media (max-width: 767px) {

  /* for mobile devices */
  .flex-div1 {
    height: auto;
    flex-direction: column;
    // width:500px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /* for tablet devices */
  .flex-div1 {
    height: auto;
    flex-direction: column;
    // max-width: 50%;
    // max-width: 650px;
  }
}

.card {
  width: 28vw;
  display: flex;
  flex: 0 0 28%;
  // display: 'flex',
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFAFA;
  background-clip: border-box;
  border: 1px solid #ccc;
  border-radius: .2rem;
  height: 700px;
  max-height: 700px;
  overflow-y: hidden !important;
  margin-left: 5px !important;
  overflow-x: hidden !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
  #main {
    // height: 500px;
    flex-direction: column;
    display: flex;
    overflow-x: hidden;

  }
}

.invoiceDetails {
  padding-left: .5rem !important;
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
  margin: 0px 7.5px;
}

.invoiceBlock {
  padding-bottom: .25rem !important;


}

.costDetails {
  justify-content: space-between;
  max-width: 500px;
  margin-left: 300px;
  // backgroundColor: "#f0f0f0",
  padding: 16px;
  border-radius: 4px;

}

@media only screen and (max-width: 480px) {

  /* for Android devices */
  .invoiceDetails {
    padding-left: .15rem !important;
    padding-top: .125rem !important;
    padding-bottom: .125rem !important;
    padding-right: .25rem !important;
    margin: 0 5px;
  }
}

@media only screen and (max-width: 768px) {

  /* for Apple devices */
  .invoiceDetails {
    padding-left: .15rem !important;
    padding-top: .125rem !important;
    padding-bottom: .125rem !important;
    padding-right: .25rem !important;
    margin: 0 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {

  /* for iPad devices */
  .invoiceDetails {
    padding-left: .5rem !important;
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
    padding-right: .25rem !important;
    margin: 0 15px;
  }
}

@media only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {

  /* for iPad mini devices */
  .invoiceDetails {
    padding-left: .15rem !important;
    padding-top: .125rem !important;
    padding-bottom: .125rem !important;
    padding-right: .25rem !important;
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {

  /* for nested max devices */
  .invoiceDetails {
    padding-left: .15rem !important;
    padding-top: .125rem !important;
    padding-bottom: .125rem !important;
    padding-right: .25rem !important;
    margin: 0 5px;
  }
}

@media (max-width: 480px) {

  /* for Android devices */
  .cardFinance {
    font-size: small;
    margin-right: 0;
    margin-bottom: 4px;
    height: auto;
  }
}

@media (max-width: 768px) {

  /* for iPad and iPad mini */
  .cardFinance {
    font-size: medium;
    margin-right: 0;
    margin-bottom: 4px;
    height: auto;


  }
}

@media (max-width: 1024px) {

  /* for nested iPad and iPad mini */
  .cardFinance {
    font-size: large;
    margin-right: 0;
    margin-bottom: 4px;
    height: auto;
    margin-bottom: 4px;

  }
}

@media (max-width: 1180px) {

  /* for nested iPad and iPad mini */
  .cardFinance {
    font-size: large;
    margin-right: 5px;
    margin-bottom: 4px;
    height: auto;
    margin-bottom: 4px;
    // width:280px;
    // max-width: 280px;
    width: 100vw;
    max-height: auto !important;
    padding-right: 10px;
  }
}


/* For mobile devices */
@media (max-width: 767px) {
  .costDetails {
    margin-left: 0;
  }
}

/* For Apple iPad and iPad mini */
@media (min-width: 768px) and (max-width: 1023px) {
  .costDetails {
    max-width: 100%;
    margin-left: 0;
    padding: 12px;
  }
}

/* For nested max */
@media (max-width: 1279px) {
  .costDetails {
    max-width: 100%;
    margin-left: 0;
    padding: 16px;
  }
}


/* For mobile devices */
@media (max-width: 767px) {
  .costDetails1 {
    margin-left: 0;
  }
}

/* For Apple iPad and iPad mini */
@media (min-width: 768px) and (max-width: 1023px) {
  .costDetails1 {
    max-width: 100%;
    margin-left: 0;
    padding: 12px;
  }
}

/* For nested max */
@media (max-width: 1279px) {
  .costDetails1 {
    max-width: 100%;
    margin-left: 0;
    padding: 16px;
  }
}

@media (max-width: 480px) {

  /* for Android devices */
  .main-div {
    display: block;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {

  /* for Apple devices */
  .main-div {
    display: block;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /* for iPad devices */
  .main-div {
    display: inline-block;
    width: 100%;
    //     max-width: 1024px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {

  /* for iPad Mini devices */
  .main-div {
    display: inline-flex;
    // max-width: 1024px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {

  /* for nested max devices */
  .main-div {
    display: inline-flex;
    // max-width: 1280px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .main-div {
    display: block;
    flex-direction: column;

  }
}

/* for Android and iOS phones */
@media (max-width: 480px) {
  .card {
    width: 100vw;
    flex: 0 0 100%;
    height: auto;
    max-height: none;
    display: inline-block;
    padding-right: 10px;

    margin-top: 32px;
    /* adjust this value as needed */
    margin-bottom: 32px;
  }
}

/* for iPad, iPad Mini, and Surface Pro 7 */
@media (min-width: 768px) and (max-width: 1024px) {
  .card {
    width: 240px;
    flex: 0 0 48%;
    height: 690;
    max-height: 690px;

    // margin-top: 32px; /* adjust this value as needed */
    // margin-bottom: 32px; /* add margin bottom to create space between cards */

  }
}

@media (min-width:768px)and (max-width: 1024px) {
  .card {
    width: 52vw;
    max-width: 100%;
    // padding-right: 20px;
    height: 400px;
    //  max-height: 650px;
  }

  .cardFinance {
    //  max-width:650px;
    height: auto;
    // width: 100vw;
    margin-right: 10px;
  }

  .flex-div1 {
    height: auto;
    margin-top: 2px;
  }

}

@media (min-width:768px)and (max-width: 1024px) {
  .card {
    width: 100vw;
    // max-width: 340px;
    height: 940px;
    max-height: 940px;
  }

  // .flexdiv {
  //   // height: 130px;
  // }
  // .main-div {
  //   // display: block;
  // }
}

@media (min-width:540px)and (max-width: 720px) {
  .card {
    width: 90vw;
    // max-width: 340px;
    height: 980px;
    max-height: 880px;
  }
}

.close {
  float: right;
}

.modalHead {
  color: #1565c0;
}

.form-group label {
  color: #063c68;
}

.cardTitle1 {
  float: left;
  // fontSize: '1rem',
  margin: 0;
  font-family: inherit;
  color: #1976D2;
  display: flex;
  align-items: center;
  font-size: 16px !important;
  font-weight: bolder !important;
  padding-left: 10px;

}

.cardHead {
  margin-bottom: 0;
  background-color: #FFFFFF;
  // borderBottom: '1px solid #ccc',
  font-weight: bold;
  font-size: 18px !important;
  font-family: Segoe UI, sans-serif;
  padding: 0;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  z-index: 1; // Make sure the element stays on top of other elements
  // backgroundColor: '#fff',
  margin-top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  // border:'1px solid #e0e0e0',
  height: 40px;
  padding-left: 4px
}

/* Mobile view */
@media (max-width: 599px) {
  .modalHead {
    font-size: 18px !important;
    box-sizing: border-box;
  }

  .modalDetail {
    height: auto;
    padding: 1rem !important;
    box-sizing: border-box;
  }

  .modalDetail input {
    // height: 430px;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
  }
}

/* Tablet view */
@media (min-width: 600px) and (max-width: 991px) {
  .modalHead {
    font-size: 20px !important;
    box-sizing: border-box;
  }

  .modalDetail {
    height: auto !important;
    box-sizing: border-box;
  }

  .modalDetail input {
    height: 430px;
    box-sizing: border-box;
    // width:100%;
    display: inline-block
  }
}

/* Desktop view */
@media (min-width: 992px) {
  .modalHead {
    font-size: 23px !important;
    box-sizing: border-box;
  }

  .modalDetail input {
    height: 430px;
    box-sizing: border-box;
    // width:100%;
    display: inline-block
  }
}

.form-field {
  width: 100%;
  /* set initial width to 100% */
}

@media only screen and (min-width: 576px) {

  /* adjust size for screens wider than 576px */
  .form-field {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) {

  /* adjust size for screens wider than 768px */
  .form-field {
    width: 60%;
  }
}

.modalDetail input {
  width: 100%;
}

.css-17w9904-MuiTypography-root {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500 !important;
  font-size: 0.8571428571428571rem;
  line-height: 1.5;
}

.css-47iiw-MuiTypography-root {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 600 !important;
  font-size: 0.8571428571428571rem;
  line-height: 1.5;
  display: block;
}


.Report {
  // margin-top: 10px;
  // margin:10px;
  width: auto;
  // margin-right:12px;
  // overflow: hidden;
}

.productionReport {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  height: 100vh;
  background-color: #8a5555;
  //border: 1px solid #ccc;
  // margin-right: 8px;
  margin-top: 10px !important;
  margin-left: 6px;
  // width:max-content;
}

/* Add more media queries as needed for other screen sizes */

.bidsOutTotals {
  height: auto !important;
  //border: 1px solid #ccc;
  margin-bottom: 1px;
  margin-top: 10px;
  margin-left: 6px;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  // margin-right: 8px;
  // margin-right: 8px;
  // width:600px;
}

.YTD {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  background-color: #fff;
  display: flex;
  margin-bottom: 5px;
  max-height: 80px;
  height: 100px;
  padding: .5rem;
  position: relative;
  // width: 100%;
  margin: 2px !important;
  height: 80px;
  margin-right: 1rem;
  // width:250px;
  // border: 1px solid #ccc;
}

.main-company-div {
  padding: 10px;
  background-color: #6c757d;
}

.Report .YTD {
  margin: 10px !important;
}

.soldOutTotals {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  height: fit-content;
  //border: 1px solid #ccc;
  margin-bottom: 1px;
  margin-top: 10px;
  margin-left: 5px;
  // margin-right: 6px;
  // width:100%;
  padding: -4 !important;
}

#leads {
  color: rgb(236, 231, 231);
  width: 75px;
  border-radius: 4px;
  // padding: 0px;
}

.thumb {
  background-color: #18adb3;
}

.dashboardicon {
  font-size: 40px !important;
}

.settingicon {
  display: inline-block;
  height: 50px !important;
  align-items: center !important;
  text-align: center;
  align-content: center !important;
  font-weight: bolder;
  overflow: visible !important;
}

.ytd-text {
  float: right;
  color: rgb(10, 5, 5);
  display: block !important;
  margin-right: 4px;
  padding: 6px;
  padding-left: 5px;
  font-size: 13px;
}

.total-no {
  font-weight: bolder !important;
  padding: 5px;
}

// .settings {
//   padding-left: 15px;
//   align-items: center;
//   font-size: 30px !important;
//   width: 10px;
//   height: 100px !important;
//   // margin-top:10px;
//   color: #ffffff;
//   font-weight: 800;
//   padding-bottom: 3px;
//   width: 70px !important;
//   margin-right: 10px;
//   margin-top: 10px;
//   overflow: visible;
// }

.product_image {
  box-sizing: content-box;

  background-size: cover;
  height: max-content;
}

.react-googlecharts {
  border: 1px solid #ccc;
  border-width: 30%;
}

.barChart {
  // margin-left: 0;
  // margin-right: 22px;
  // padding-right: 40px;
  padding: 0 !important;
  margin-top: 0 !important;
  width: auto !important;
}

.react-bar-chart {
  margin-left: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  #form-control {
    padding-left: 10px;
    padding-right: 10px;
    width: 560px !important;
  }

  #form-control1 {
    padding-left: 10px;
    padding-right: 10px;
    width: 450px !important;
  }

  #cardicon {
    width: 110px !important;
  }

  #card-month,
  #card-year {
    // padding-left: 10px;
    width: 130px !important;
  }

  #CVV {
    width: 270px !important;
  }

  .card-footer {
    padding-left: 13px;
    padding-right: 13px;
    width: 570px !important;
  }
}


.cardTitle {
  float: left;
  font-size: 1.1rem !important;
  margin: 0 !important;
  font-family: inherit !important;
  // color: 'inherit',
  display: flex !important;
  align-items: center !important;
  // fontSize: '18px',
  font-weight: bold !important;
  color: #1565c0 !important;

}

.salesTeam-table {
  border-radius: 3px;
  //  margin-right: 16px;
  //  width:100%;
  box-sizing: border-box;
}

.salesTeam-table,
.salesTeam-head,
.salesTeam-body {
  border: 1px solid rgba(0, 0, 0, .125);

  align-items: center !important;
  align-content: center !important;
  // padding: 16px;
  // display:inline-grid;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: medium;
  margin-bottom: 16px;
  word-wrap: break-word;
  position: relative;
  // margin-left: 4px;
  // margin-right: 4px;
}

.salesTeam-head th {
  border: 1px solid rgba(0, 0, 0, .125);
  font-weight: bolder;
  text-align: center;
  padding: 8px;
}

.salesTeam-body td {
  border: 1px solid rgba(0, 0, 0, .125);
  align-items: center !important;
  align-content: center !important;
  text-align: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 100;
}

.perform-percent {
  padding: 2px;
  padding-left: 3px;
  padding-right: 3px;
}

.salesTeam-tableContainer {
  margin: 7px;
  margin-right: 10px !important;


}

.table-div {
  // margin-right:4px;
  // border:1px solid #ccc;
  // margin:4px;
  width: 98%;
}

#soldOutTotal {
  height: fit-content;
}

// #customerSatisfaction{
//   height:auto;

// }
.progress {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   // top:20%;
  //   // bottom: 50%;
  //   // right:50%;
  //   transform: translate(-50%, -50%);
  //   z-index: 0; // set the progress bar behind the button
  //   width: 200px;
  //   height: 200px; 
  // color:#64a530 !important;
  // align-items: center !important;
  // align-content: center !important;
  // padding:50px ;
  margin-left: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
}

#ProgressBar {
  height: fit-content !important;
  // padding: 90px;
  // border-radius: 50%;
  // height:100%;
  // width:100%;

}

.my-tooltip {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  color: #333333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  padding: 10px;
  background-color: transparent rgb(10, 5, 5);

  &.google-visualization-tooltip {
    max-width: 300px;
  }
}

.reactBarCharts {
  padding: 0 !important;
  height: auto !important;
  position: relative;
  top: -30px !important;
  // left: -15px !important;
  width: fit-content;
  box-sizing: content-box !important;
  padding-right: 0%;
  align-items: center;
  align-content: center;
  left: -11px;
}

@media (max-width: 767px) {
  .MuiGrid-container {
    display: flex !important;
    flex-wrap: wrap;
  }

  .MuiGrid-item {
    flex: 1 0 50%;
  }
}

#sales-to-target {
  margin-right: 8px !important;
}


// @media (min-width:768px)and (max-width: 1024px) {
//   #paymentinfo {
//     width: fit-content;
//     display: flex;
//   }
// }

// @media (max-width: 480px) {
//   #paymentinfo {
//     display: inline-flex;
//     width: fit-content;


//   }
// }

// @media (min-width: 600px)
// {}


@media (min-width: 768px) and (max-width: 1024px) {

  .MuiGrid-grid-sm-6 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .barChart {
    width: 900px !important;
    height: 380px;
  }
}

// .MuiGrid-spacing-xs-1 > .MuiGrid-item {
//   padding:0 !important;

@media (max-width: 480px) {
  .barChart {
    width: 430px !important;
    height: 380px;
  }

  .reactBarCharts {
    left: -8px;
  }
}

$primary-color: #343a40;
$font-family: "Segoe UI', sans-serif";
$primary-pink: #b6186a;

.card-head {
  margin-bottom: 0 !important;
  background-color: #FFFFFF !important;
  // border-bottom: 1px solid #ccc;
  font-weight: bold !important;
  font-size: 18px !important;
  font-family: $font-family !important;
  padding: 0 !important;
  padding-bottom: 0 !important;
  position: sticky;
  top: 0 !important;
  z-index: 1; // Make sure the element stays on top of other elements
  // background-color: #fff;
  margin-top: 0 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25) !important;
  // border: 1px solid #e0e0e0;
  height: 50px;
  padding-left: 4px;

  .time-right {
    color: $primary-color !important;
  }

  .avatar {
    width: 60px !important;
    height: 60px !important;
    margin-right: 10px !important;
  }

  .list-item {
    font-size: small !important;
  }
}

// @media (min-width: 600px){
// .MuiToolbar-regular {
//     min-height: 32px  !important;
// }
// }
// @media (min-width: 0px) and (orientation: landscape){
// .MuiToolbar-regular {
//     max-height: 33px !important;
// }
// }


$breakpoint-sm: 600px; // Change the breakpoint to match 'sm' breakpoint

.Toolbar {
  border: 1px solid #ccc !important;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #FFFFFF;
  color: #2f4f4f;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  padding: 0;
  position: sticky;
  top: 0px;
  min-height: 35px !important;
  padding-top: 0;
  color: inherit;

  @media only screen and (max-width: $breakpoint-sm) {
    // Use SCSS media query with the new breakpoint
    flex-direction: column;
    align-items: center;

    &>* {
      margin: spacing(0.5);
    }
  }
}

.toolButton {
  color: red;
  border: 1px solid grey;
  // padding-left: 3px;
}

.flexStart {
  border-bottom: 1px solid #dedede;
  position: relative;
  // borderTopLeftRadius: theme.shape.borderRadius,
  // borderTopRightRadius: theme.shape.borderRadius,
  // borderBottomLeftRadius: theme.shape.borderRadius,
  // borderBottomRightRadius: theme.shape.borderRadius,
  border-radius: 4px 4px 4px 4px;
  font-family: Segoe UI, sans-serif;
  display: block;
  align-items: flex-start;
  margin: 4px 0;
  padding-left: 16px !important;
  font-size: 16;
  font-weight: 400;
  line-height: 1.3;
  color: #212529;
  background-color: #F1F1F1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin-bottom: 7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: auto;
  border: 2px solid #dedede;
  padding: 0px !important;

}

.h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.icon {
  font-family: Font Awesome 5 Free;
  // WebkitFontSmoothing: 'antialiased',
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-right: 1px;
}

.cardContainer {
  margin-left: 2px !important;
  margin-bottom: 1.5px !important;
  transform: scale(1.0) !important;
  padding: 1px !important;
  width: 100% !important;
  height: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  //   & > * {
  //   margin: 0.2rem;
  // }
}


.timeRight {
  color: #343a40 !important;

}

.avatar {
  width: 60px !important;
  height: 60px !important;
  margin-right: 10px !important;
}

.listItem {
  font-size: small !important;
}

// .cardContainer {
//   display: flex;
//   flex-wrap: wrap;

//   width: 100vw;
//   height: 620px;
//   padding: 0 !important;
//   border-radius: 4px;
//   font-weight: 500;
//   font-size: 18px;
//   font-family: 'Segoe UI';
//   justify-content: space-between;
// }

.middleSection {
  display: flex;
  justify-content: center;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.paper {
  position: absolute;
  width: auto !important;
  height: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50% !important, -50% !important);
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -moz-scrollbars-none: scrollbar;
  -webkit-scrollbars-none: scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 600px) {
    width: 80%;
    height: 50%;
  }

  @media (min-width: 960px) {
    width: 56%;
  }
}

$spacing: 8px; // Set your desired spacing value

.root {
  display: flex;
  flex-wrap: wrap;

  &>* {
    margin: #{$spacing / 2};
  }

  width: 100vw;
  height: 620px;
  // border: 1px solid #ccc;
  padding: 0 !important;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  font-family: 'Segoe UI';
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  justify-content: space-between; // new style
}

///
/// 
/// 
.avatar {
  font-size: small;
  height: 80px;
  width: 80px;
  // border-bottom:1px solid #f1f1f1;
}

.userCard {
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: .25rem;
  background-color: #fff;
  display: flex;
  margin-bottom: -2px;
  // max-height: 80px;
  // height: 100px;
  // padding: .5rem;
  position: relative;
  // width: 100%;
  // margin: 5px !important;
  // height: 80px;
  // margin-right: 1rem;
  // width:250px;
  border: 1px solid #ccc;
  border-top: 3px solid #2770b2;
  color: #242629;
  // font-weight: 200;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  margin-right: 12px;
  margin-bottom: 7px;
  margin-left: 10px;

}

.cardDetails {
  // border-bottom: 1px solid #dedede;
  color: #242629;
  // font-weight: 200;
  font-size: small;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: block !important;


}

.userDetails {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;

  // font-family: ;
}

.userName {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;
  font-weight: 600;
}

.userBottom {
  display: inline-flex !important;
  margin-left: 10px;
  //  margin-right:10px;
}

.role {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  color: #585656;
  margin-top: 15px;
}

.assignUsers {
  justify-content: center;
  align-items: center;
  // display:block;
  // height:125px;
  display: flex;

  // height:40px !important;
  // width:30px !important;
}

.addUserIcon {
  height: 40px !important;
  width: 30px !important;
  font-weight: 400px !important;
}

.addUsers {
  justify-content: center;
  align-items: center;
  height: 115px !important;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  //  margin-top:50%;
  //  margin-bottom: 50%;
}

// .assignUsers1{
//   justify-content: center;
//   align-items: center;
//   // display:block;
//   // height:125px;
//   display:flex;

//     // height:40px !important;
//   // width:30px !important;
// }
.userEmail {
  font-weight: 400;
  font-size: 13px;
  color: #495057;
}

.projectHeader {
  font-size: 19px !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 1px;
  color: #212529;
  font-weight: 600 !important;
  padding-left: 7px;
}

.projectProgress {
  color: #f5ad5f;
  font-family: var(--font);
  font-size: 17px !important;
  margin: 0;
  vertical-align: middle;
  font-weight: 300 !important;
}

.projectHead {
  display: flex;
  padding-left: 4px;
}

.userId {
  font-weight: 300;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  margin-left: 7px;

}

.projectProgress1 {
  color: #4d4d4d;
  font-family: var(--font);
  font-size: 14px !important;
  margin: 0;
  vertical-align: middle;
  font-weight: 400 !important;

}

.Navbar1 {
  // background-color: rgb(18, 18, 19);
  position: fixed;

  left: auto;
  right: 0;
  box-sizing: border-box;
  font-size: 18px !important;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 0px 0px silver !important;
  background-color: #ffffff !important;
  color: black !important;
  border-top: 1px solid #ece9e9;
  margin-bottom: 20px;
}

.toolbar1 {
  display: flex !important;
  min-height: 49px !important;
  align-items: center !important;
  // padding: 0px 5px;
  max-height: 49px;
  // box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.07);
  // border-right:1px solid #797575;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-left: 0px !important;
  border-right: 0px !important;

}

.Logo {
  margin-right: 10px;
}

.Title {
  flex-grow: 1;
}

.login {
  float: right;
}

.css-1t6e9jv-MuiCardActions-root {
  padding: 3px !important;
}

.navBarHead {
  display: inline-flex;
  margin: 0;
  white-space: normal;
  font-size: 18px;
  font-weight: bold;
  font-size: 19px !important;
  margin-left: -10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;

}

.projectNavBar {
  padding-right: 15px;
  font-size: 12px !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: 600 !important;
  color: #555;
  // border-right:1px solid #797575;
  padding-left: 16px;
  text-decoration: none;
  display: inline-flex;
  margin: 0;
  white-space: normal;
  font-size: 18px;
  font-weight: bold;
  // font-size: 19px !important;
}

.icon {
  // top:5px;
  padding-right: 2px;
  height: 0.95rem !important;
}

.projectNavBar:active {
  // border-bottom: 3px solid #007bff;
  color: black;
  // font-size: 17px;
  // font-weight:800px !important;
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  // text-decoration: none;

}

.css-1dlh8so-MuiTabs-indicator {
  bottom: 15px !important;
}

.mainUser {
  // border:1px solid #dfdddd;
  // padding-left: 8px;
  // padding-top:8px;
  height: 100vh;
}

// 1234main styled and main

.ps-active {
  color: #fff !important;
  // background-color: $primary-pink !important;
}

.ps-menu-label {
  padding: "5px 35px 5px 20px !important"
}

.ps-menu-icon {
  background-color: transparent !important;
}

.ps-sidebar-container {
  background-color: #efefef !important;
  height: 100% !important;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
}

.ps-menu-button:hover {
  color: #fff !important;
  background-color: rgba($primary-pink, .8) !important;
}



.poOfficeTable {
  font-family: 'Poppins', sans-serif;
  justify-content: left;
  text-align: left;
}

.poDetails {
  justify-content: left;
  text-align: left;
}

.POCard {
  background-color: rgb(255, 255, 255) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  // box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  background-image: none !important;
  overflow: hidden !important;
}

.hr {
  color: rgba(47, 43, 61, 0.78) !important;
  background-color: rgb(255, 255, 255) !important;

}

.poFooter {
  float: right;

}

// .poButton {

//   display: inline-flex !important;
//   -webkit-box-align: center !important;
//   align-items: center !important;
//   -webkit-box-pack: center !important;
//   justify-content: center !important;
//   position: relative !important;
//   box-sizing: border-box !important;
//   -webkit-tap-highlight-color: transparent !important;
//   outline: 0px !important;
//   border: 0px !important;
//   margin: 0px !important;
//   cursor: pointer !important;
//   user-select: none !important;
//   vertical-align: middle !important;
//   appearance: none !important;
//   text-decoration: none !important;
//   line-height: 1.2 !important;
//   font-size: 0.9375rem !important;
//   letter-spacing: 0.43px !important;
//   font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//   font-weight: 500 !important;
//   border-radius: 6px !important;
//   color: rgb(255, 255, 255) !important;
//   // background-color: rgb(115, 103, 240) !important;
//   min-width: 50px !important;
//   text-transform: none !important;
//   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
//   box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px !important;

// }

.textfield {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-right: 12px;
}

.textfield {
  color: #50253c;
}

// .formHeader{

//   font-size:18px;
// }
.button {
  margin-right: 10px !important;
}

.check {
  font-size: 16px;

}

// .tablerows{
//   font-size: 15px !important;
//   font-weight:500 !important;

// }
.anchor {
  font-size: 15px !important;
  color: #0675cf;
  font-weight: 500 !important;

}

.tableHead {
  border-bottom: 2px solid rgb(190, 190, 190) !important;
}

.searchBar {
  padding-left: 16px;
  padding-top: 16px;
  margin-bottom: 5px;
  display: flex
}

.searchField {
  border: 1px solid #a1a0a0;
  transform: 'scale(0.8)';
  max-height: 40px !important;
}

.icons {
  float: right;
}


// Common styles
.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

// Common styles


.text-trans-none {
  text-transform: none !important;
}

.button-spacing {
  padding: 0.25rem !important;
}

.tab-font-size {
  font-size: 0.95rem !important;
}




// .drawer-scrollbar {
//   width: 10px; 
//   background-color: 'blue';
// }

// .drawer-scrollbar-thumb {
//   background-color: 'blue'; 
//   border-radius: 5px; 
// }

.primaryTonalButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #7367f029 !important;
  border-color: none !important;
  border-radius: 6px !important;
  color: #7367f0 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #7367f03d !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.secondaryButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #00cfe829 !important;
  border-color: none !important;
  border-radius: 6px !important;
  color: #00cfe8 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #00cfe829 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.dangerButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #ea545529 !important;
  border-color: none !important;
  border-radius: 6px !important;
  color: #ea5455 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #ea545529 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.sucessButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #28c76f29 !important;
  border-color: none !important;
  border-radius: 6px !important;
  color: #28c76f !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #28c76f29 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.warningButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #ff9f4329 !important;
  border-color: none !important;
  border-radius: 6px !important;
  color: #ff9f43 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #ff9f4329 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.alignCenter {
  display: flex !important;
  justify-content: center I !important;
  align-items: center !important;
}

.btn {
  text-transform: none !important;
}


.tableHead {
  // border-bottom: 2px solid rgb(214, 214, 214) !important;
}

.searchBar {
  padding-left: 16px;
  padding-top: 16px;
  margin-bottom: 5px;
  display: flex
}

.searchField {
  border: 1px solid #a1a0a0;
  transform: 'scale(0.8)';
  max-height: 40px !important;
}

.icons {
  float: right;
}

.tabs {
  font-size: 14px !important;
  font-family: 'Poppins' !important;
  text-decoration: none;
  /* font-weight: 500 !important; */
}

.tooltip {
  float: right;
}

.table-row {
  border: 1px dashed #ccc;
  padding: 16px !important;
  font-size: 14px;
}

.itemDetails {
  padding-left: 15px;
}

.poTableDetails {
  font-size: 13px !important;

}

.addTableRow {
  margin-right: 6px !important;
}

.filter {
  // border:1px solid rgb(100, 100, 100);
  border-radius: 10px !important;
  font-weight: 500 !important;
}

.button {
  text-decoration: none !important;
}

.feedBack {

  padding-right: 15px;
  display: inline-flex;
  justify-content: center;
  place-items: center;
}

.manageBills {
  float: right;
  margin-top: 10px;
}

.totalAmount {
  float: right;
  padding: 4px;
}

.amount {
  font-weight: 600;

}

.iconTextCenter {
  justify-content: center;
  display: flex;
  float: left;
}

.formHeader {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.imgLogo {
  max-height: 60px !important;
  // padding-left: 60px;

}

.headDetails {
  font-size: 16px;
  font-size: 500 !important;
  // color:#083295;
}

.poImgText {
  justify-content: center;
  place-items: center;
  display: grid;
}

.editEmployee {
  float: right;
  margin-top: 0px;
  display: in;
}

.employeeEditIcon {
  font-size: 15px !important;
}

.addSubTotal {
  margin-left: 8px !important;
}

.actionLink {
  color: #007BFF !important;
}

.tableHead {
  background-color: #ededf0ad;
  color: #5d596c !important;
  // border-top: 1px solid  rgb(90, 90, 90) !important;
  min-height: 55px !important;

}

// .amountDetails{
//   background-color: #eae8fd !important;
//   color: #7367f0 !important;
// }
.cancelbtn {
  color: #929396 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  background: #f1f1f2 !important;
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: all .135s ease-in-out;
  transform: scale(1.001);
}

.sendIcon {
  display: flex;
  place-items: center;
}

.tableHead {
  background-color: #ededf0ad;
  color: #5d596c !important;
  // border-top: 1px solid  rgb(90, 90, 90) !important;
  min-height: 55px !important;

}

.orderNo {
  color: #0052bd !important;
  font-weight: 500;

}

.estimateCost {
  color: #bd0075 !important;
  font-size: 15px !important;
  font-weight: 500;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.statusDetails {
  background-color: #dff7e9 !important;
  color: #28c76f !important;
  justify-content: center;
  display: flex;
  text-align: center;
  // place-items: center;
}

.headerTable {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.235;

}

.checkoutDetails {
  background-color: #ffa24b !important;
  color: #fff !important;
  justify-content: center;
  display: flex;
  font-size: 12px !important;
  max-width: fit-content;
  padding: 1px;
  // place-items: center;
  border-radius: 7px;
}

// .projectStatusDate{
//   --bs-text-opacity: 1;
//     color: #a5a3ae !important;
//     font-size: 12px !important;
// }
.tableRows {
  border: 1px dashed #ccc !important;
  padding: 15px !important;
  font-size: 14px !important;
  padding: 10px !important;
}

.primaryTonalButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #3c5cfa36 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #3c5bfa !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #3c5cfa31 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}


.primaryTonalButtons {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #4760dfd0 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #fff !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #3c5cfa31 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.greyTonalButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #7777772c !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #777777 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #7777772c !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.secondaryButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #00cfe829 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #00cfe8 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #00cfe829 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.dangerButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #ea545529 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #ea5455 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #ea545529 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}



.dangerButtons {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 14px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #ea5455d6 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #fff !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #ea545529 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}



.sucessButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #28c76f29 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #28c76f !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #28c76f29 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}


.sucessButtons {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 21px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #2da765ba !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #fff !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #28c76f29 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.warningButton {
  box-shadow: none !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 6px 12px !important;
  border: 2px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.43px !important;
  background-color: #ff9f4329 !important;
  border-color: none !important;
  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  color: #ff9f43 !important;
  font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

  &:hover {
    background-color: #ff9f4329 !important;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}


.task-list {
  // flex: 0 0 auto; /* This ensures that the task-list won't grow or shrink */
  // overflow: hidden; /* Hide content that overflows */
  border-right: 1px solid #ddd;
  /* Add a border between task-list and resizer */
}

.resizer {
  width: 20px;
  cursor: col-resize;
  /* Set cursor style for the resizer */
  background-color: #42b362;
}

.task-gantt {
  flex: 1;
  /* This will make task-gantt take up the remaining space */
  overflow: hidden;
  /* Hide content that overflows */
}


.split-sash-content {
  width: 100%;
  height: 100%;
  background-color: #007bff;
}

.salesStatus {
  border-radius: 7px !important;
}


.message-container::-webkit-scrollbar {
  width: 5px;
}

.message-container::-webkit-scrollbar-thumb {
  background-color: #2182f8;
}



.newchat-content::-webkit-scrollbar {
  width: 7px;
}

.newchat-content::-webkit-scrollbar-thumb {
  background-color: #2182f8;
}

.newchat-container {
  border: 1.9px solid #dee0e3;
  border-bottom: none;
  border-radius: 17px !important;
  // border-top-right-radius: 17px !important;
  // border-top-left-radius: 17px !important;
}

.newChat-card-header {
  background-color: #2679ff !important;
  color: white;
  padding: 15px !important;
}

.newChat-textfield {
  margin: 0.8em;
  margin-right: 10px !important;
}

// .newChat-avatar {
//   margin-right: 4px;
//   margin-left: 4px;
// }


.MuiCardHeader-avatar {
  margin-right: 10px !important;
}

.projectRendering {
  justify-content: center !important;
  place-items: center !important;
  display: flex !important;
}

.loaderCircularBar {


  top: 50% !important;
}

.overlay {
  position: fixed !important;
  // display: none !important;
  // width: 100% !important;
  // height: 100% !important;
  // top: 0 !important;
  // left: 0 !important;
  // right: 0 !important;
  // bottom: 0 !important;
  // background-color: rgba(0,0,0,0.25) !important;
  z-index: 2 !important;
  cursor: pointer !important;
}

.topBarLogo {
  height: 50px !important;
}

.alignEnd {
  display: flex !important;
  justify-content: flex-end !important;
}

.createbidbtnnext {

  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  font-weight: 500 !important;
}

.SucessSolid {

  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  font-weight: 500 !important;
  background-color: #2e7d32 !important;
  color: white !important
}

.CancelSolid {

  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  font-weight: 500 !important;
  background-color: #ff2a2a !important;
  color: white !important
}

.primarySolid {

  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  font-weight: 500 !important;
  background-color: #3c5bfa!important;
  color: white !important;
  // min-width: 50px !important;
  min-width: 150px !important;
}
.SucessSolids {

  border-radius: 8px !important;
  margin-bottom: 10px !important;
  margin-top: 7px !important;
  font-weight: 500 !important;
  background-color: #2e7d32 !important;
  color: white !important;
  min-width: 150px !important;
}


.custom-drawer {
  background-color: #ff2a2a;
  border-right: 1px solid #ccc;
}

/* styles.css */

// .MuiAvatar-root {

//   background-color:#7f00ffd4  !important;

// }

// .contractCard {
//   display: flex;
//   flex-shrink: 1;
//   background: rgba(0,0,0,.06);
//   background: var(--palette-black-alpha-6,rgba(0, 0, 0, .06));
//   padding: 8px;
//   border-radius: 4px;
//   margin-top: 8px;
//   margin-bottom: 8px;
//   overflow: hidden;
// }

.contractCard {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  background-color: #fff;
  display: flex;
  margin-bottom: 5px;

  padding: 0.5rem;
  position: relative;
  margin-top: 12px !important;

}

.cardBoxShadow {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  background-color: #fff;

  margin-bottom: 5px;

  // padding: 0.5rem;
  position: relative;
  margin-top: 12px !important;
}

.flaotEnd {
  justify-content: flex-end;
  display: flex;
}

.highlightheading {
  font-size: 13px !important;
  color: #012bff !important;
  font-weight: 400 !important;
}

.highlightsubheading {
  font-size: 15px !important;
  color: #808080;

  font-weight: 500 !important;
}

.iconheading {
  font-size: 18px !important;
  color: #012bff !important;
  margin-bottom: -2px !important;

}

.paymentRptHead {
  font-size: 16px !important;
}

.successButton {
  background-color: #4aa02c !important;
  color: white !important;
}

.statusactive {
    color: #56ca00 !important;
    background-color: #00800029 !important;
    // border-radius: 16px;
    // align-items: center;
    // justify-content: center;
    // font-size: 0.8125rem;
    // height: 20px;
    border-radius: 7px !important;
    justify-content: center;
    display: flex;
    text-align: center;
}
.payment{
  color:rgb(0, 0, 0) !important;
  background-color: rgba(102, 22, 8, 0.12) !important;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
}
.statusvisit{
  color:rgb(255, 17, 0) !important;
  background-color: rgba(255, 180, 0, 0.12) !important;
  // border-radius: 16px;
  // align-items: center;
  // justify-content: center;
  // font-size:  0.8125rem;
  // height: 30px;
  border-radius: 7px !important;
  justify-content: center;
  display: flex;
  text-align: center;
}

.success {
  background-color: #4aa02c !important;
  border-radius: none !important;
  place-items: center;
  display: flex;
  justify-content: center;
}

.error {
  background-color: #df3333ed !important;
  border-radius: none !important;
  place-items: center;
  display: flex;
  justify-content: center;
}

.warning {
  background-color: orange !important;
  border-radius: none !important;
  place-items: center;
  display: flex;
  justify-content: center;
}

.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.bg {
  /* The image used */
  // background-image: url("img_girl.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contractStatus{
  background-color: #258039 !important;
  color: #fff !important;
  justify-content: center;
  display: flex;
  text-align: center;
  border-radius: 7px;
}
.statusActive{
  background-color: #258039 !important;
  color: #fff !important;
  justify-content: center;
  display: flex;
  text-align: center;
  border-radius: 7px;
}

.text-align-center{
  text-align: center;
}
.provisioningImage{
  min-width: 104px;
min-height: fit-content;
max-width: 173px;
}
.questionBuilderCard {
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 6px !important;
  background-color: #fff;
  // display: flex;
  margin-bottom: 5px;

  padding: 0.5rem;
  // position: relative;
  margin-top: 12px !important;

}



.linearrow {
    border: 5px solid rgb(92, 174, 92);
    border-radius: 5px;
}

.arrow {
	position:absolute;
	left:50%;
}

.arrow_line {
	width:2px;
  border: 6px solid rgb(80, 142, 80);
  border-radius: 5px;
	background:rgb(80, 142, 80);
  height:60px;
	margin:auto;
}

.arrow_point {
	box-sizing: border-box;
	height: 25px;
	width: 25px;
	border-style: solid;
	border-color: rgb(80, 142, 80);
	border-width: 0px 2px 2px 0px;
	transform: rotate(225deg);
	transition: border-width 150ms ease-in-out;
	margin-top: -24px;
}

.float-left-box {
  float: left; padding: 15px 0 0 45px;
}


/* vertical arrows */
.line-vertical {
  width: 8px; /* line-width */
  height: 325px; /* line length */
  margin-left: 30px;
  margin-top: -315px;
  float: left;
}

/* common arrow styles */
.line-vertical {
  position: relative;
  border-radius: 5px;
  background: rgb(80, 142, 80);
}
.arrow-up:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 25px solid transparent;
}

/* vertical arrows */
.arrow-up:after {
  left: -19px;
}
.arrow-up:after {
  top: -48px;
  border-bottom: 24px solid rgb(80, 142, 80);
}


.iconcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 40%;
  transform: rotate(270deg);
}
.tradeTab{
  background-color: #c2cbf8 !important;
  border:1px solid  #c4c4c4 !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

}